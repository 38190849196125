import { useState, useContext, createContext } from "react";
import { Auth } from "aws-amplify";

async function signOut() {
  try {
    await Auth.signOut({ global: true });
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

const DataContext = createContext({
  links: [],
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
});

const HOME = { title: "Home", href: "/", icon: "home" };
const SERVICES = { title: "Services", href: "/services", icon: "services" };
const PRICING = { title: "Pricing", href: "/pricing", icon: "pricing" };
const PAYMENT = { title: "Payment", href: "/payment", icon: "pricing" };
const BLOG = { title: "Blog", href: "/blog", icon: "blog" };
const PROFILE = { title: "Profile", href: "/profile", icon: "profile" };
const LOGOUT = { title: "Logout", onClick: signOut, icon: "logout" };

export function DataProvider({ children }) {
  // const links = [HOME, SERVICES, PRICING, BLOG, PROFILE, LOGOUT];
  const links = [PROFILE, PAYMENT, BLOG, LOGOUT];
  const value = { links };
  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}

export function useData() {
  return useContext(DataContext);
}
