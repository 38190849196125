import MenuItem from "./MenuItem";
import { useData } from "./Context";
import { Auth } from "aws-amplify";

function Menu() {
  console.log(Auth.user);
  const { links } = useData();
  return (
    <nav>
      <ul className="menu">
        <span>Welcome {Auth.user.attributes['name']}</span>
        {links.map(({ title, ...props }) => (
          <MenuItem key={title} {...props}>
            {title}
          </MenuItem>
        ))}
      </ul>
    </nav>
  );
}

export default Menu;
