import React, {useContext} from "react"
import Button from "./Button";
import Transaction from "./Transaction"
import {DragDropContext, SheetsContext, ClipboardContext} from './Ledger';

import {addMonth} from './lib/sheet';
import {parse as parseMoney} from './lib/money';

// Responsible for Column body.
// One column per bank account / future income and payments

function ColumnBody({transfers, index, modify}){
  const sh = useContext(SheetsContext);
  const clipboard = useContext(ClipboardContext);
  const dragging = useContext(DragDropContext);
  const draggingRow = dragging.row;

  const addIncome = (v) => { addRow(true); };
  const addPayment = (v) => { addRow(false); }
  const addRow = (isIncome) => {
    const col = Object.entries(sh.columns)[index][1];
    const transfer = {
        comment: `new row ${transfers.length}`,
        date: sh.start, // parse
        exact: col.type !== "future",
    };
    if (isIncome) transfer.income = "0.01"; // new Money('', 0.01);
    else          transfer.payment = "0.01"; // new Money('', 0.01);
    transfers.push(transfer);
    modify(sh);
  };
  const deleteRow = (idx) => {
    delete transfers[idx];
    modify(sh);
  };
  const update = (row, prop) => {
    return (evt) => {
      evt.preventDefault();
      // validations
      if (prop === "income" && transfers[row]["payment"] !== undefined) return;
      if (prop === "payment" && transfers[row]["income"] !== undefined) return;
      const val = evt.target.value;
      console.log(`${prop} => ${val} | ${sh.start} | ${addMonth(sh.start)}`);
      if (prop === "date" && (val < sh.start || val >= addMonth(sh.start))) return;
      if ((prop === "income" || prop === "payment") && parseMoney(val).amount < 0) return;
      transfers[row][prop] = val;
      modify(sh);
    };
  };
  const paste = (v) => {
    const row = clipboard.clipboard;
    const rowDate = new Date(Date.parse(row.date)), sheetDate = new Date(Date.parse(sh.start));
    if (rowDate.getDay() > 27) rowDate.setDay(27);
    rowDate.setFullYear(sheetDate.getFullYear());
    rowDate.setMonth(sheetDate.getMonth());
    row.date = rowDate.toISOString();
    transfers.push(row);
    clipboard.set(null);
    modify(sh);
  };
  const copy = (idx) =>{
    clipboard.clipboard = transfers[idx];
    clipboard.set(clipboard.clipboard);
  };
  const cut = (idx) =>{
    copy(idx);
    deleteRow(idx);
  };
  const handleDragLeave = (evt) => evt.preventDefault();
  const handleDragEnter = (evt) => evt.preventDefault();
  const handleDragOver = (evt) => evt.preventDefault();
  const handleDrop = (evt) => {
    evt.preventDefault();
    dragging.setRow(null);
    transfers.push(draggingRow);
    modify(sh);
    dragging.drop && dragging.drop.action();
    dragging.setDrop(null);
  };

  return (
    <div>
      <table border = '1px solid black' style={{borderCollapse: 'collapse'}}>
        <thead>
          <tr>
            <td className='col_1'>In</td>
            <td className='col_2'>Out</td>
            <td className='col_3'>Comment</td>
            <td className='col_4'>When</td>
            <td className='col_5'>&nbsp;</td>
          </tr>
        </thead>
        {transfers
           ?
           <tbody
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}>
             {
                transfers.map( (row, idx) =>
    	              <Transaction
                      key={idx}
                      row = {row}
                      name = {`trx_${index}_${idx}`}
                      updateIncome = {update(idx, "income")}
                      updatePayment = {update(idx, "payment")}
                      updateComment = {update(idx, "comment")}
                      updateDate = {update(idx, "date")}
                      deleteRow = {(v) => {deleteRow(idx)}}
                      cut = {(v) => {cut(idx)}}
                      copy = {(v) => {copy(idx)}}/>
                 )
               }
           </tbody>
           : ''
         }
      </table>
      <span style = {{display: 'flex', flexFlow: 'row', gap: '55px'}} >
        <Button className = 'income' title="Add Income" icon="plus" label="add income" text="Add Income" onClick = {addIncome} />
        <Button className = 'payment' title="Add Payment" icon="plus" label="add payment" text="Add Payment" onClick = {addPayment} />
        <Button className = 'paste' title="Paste Row"
                disabled={clipboard.clipboard === null}
                icon="paste" label="paste" text="Paste Row" onClick = {paste} />
      </span>
    </div>
  );
}

export default ColumnBody;
