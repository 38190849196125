import React from "react";

function Button({ as = "button", className = "", disabled = false, icon, label, text, ...rest }) {
  const Element = as;
  return (
    <Element className={`icon-button ${className}`} {...rest}>
      <img src={`icons/${icon}${ disabled ? '-disabled' : '' }.svg`} alt={label} />{text}
    </Element>
  );
}

export default Button;
