import React, {useContext} from "react"
import { DragDropContext } from "./Ledger"
import Button from "./Button"

function Transaction({row, updateIncome, updatePayment, updateComment, updateDate, deleteRow, cut, copy, name}) {
  const dragging = useContext(DragDropContext);
  const handleDragStart = (evt) => {
    evt.dataTransfer.dropEffect = "move";
    dragging.setRow(row);
    dragging.setDrop({action: deleteRow});
  };

  return <tr draggable = {cut != null}
             onDragStart={handleDragStart}
             className = {(row.exact === undefined || row.exact) ? 'plain' : 'italic'}>
       <td className = 'income col_1'>
          <input name={`${name}_income`} onChange={updateIncome} disabled = {!updateIncome} type='numeric' maxLength='9' value={row.income || ''}/>
       </td>
       <td className = 'payment col_2'>
          <input name={`${name}_payment`} onChange={updatePayment} disabled = {!updatePayment} type='numeric' maxLength='9' value={row.payment || ''}/>
       </td>
       <td className='col_3'>
         <textarea name={`${name}_comment`} onChange={updateComment} disabled = {!updateComment} value = {row.comment}/>
       </td>
       <td className='col_4'>
         <input onChange={updateDate} name={`${name}_date`} disabled = {!updateDate} type='date' value={(new Date(Date.parse(row.date))).toISOString().substring(0,10)}/>
       </td>
       <td className='col_5'>
         <span style = {{display: 'flex', flexFlow: 'row', gap: '8px', margin: '5px'}} >
             <Button style={{width: '16px', height: '16px'}} title="Delete Row" icon="trash" label="delete row"
                     disabled = {!deleteRow} onClick = {deleteRow}/>
             <Button style={{width: '16px', height: '16px'}} title="Cut Row" icon="cut" label="cut row"
                     disabled = {!cut} onClick = {cut}/>
             <Button style={{width: '16px', height: '16px'}} title="Copy Row" icon="copy" label="copy row"
                     disabled = {!copy} onClick = {copy}/>
         </span>
       </td>
    </tr>;
}

export default Transaction;
