import React, {useContext, useState} from "react";
import ColumnHeader from "./ColumnHeader";
import ColumnBody from "./ColumnBody";
import Button from "./Button";
import {BrowserView, MobileView} from 'react-device-detect';
import {SheetsContext, UndoContext} from './Ledger';
// Responsible for representing one monthly sheet
// and switching between sheet (go to next, go to previous, create next month from the current one).

function Sheet({nextSheet, prevSheet, derive, modify}) {
  const sh = useContext(SheetsContext);
  const [idx, setIdx] = useState(0);
  const undoActions = useContext(UndoContext);

  const len = sh && sh.columns ? Object.entries(sh.columns).length : 0;

  const nextColumn = len > idx ? () => { len > idx+1 && setIdx(idx+1) } : undefined;
  const prevColumn = idx > 0 ? () => { setIdx(idx-1) } : undefined;
  const addColumn = (v) => {
    const col = {
      place: "J1",
      description: "new empty column",
      type: "checking_account",
      balance: "$00.00",
      transfers: []
    };
    let name = "new_column", nidx = 0;
    while ( sh.columns.hasOwnProperty(name) && nidx < 10) { name = `new_column_${++nidx}`;  }
    sh.columns[name] = col;
    modify(sh);
  };
  
  const month  = sh ? new Date(Date.parse(sh.start)) : new Date();
  const balance = sh ? sh.balance : '$0.00';
  const future = sh ? sh.future : '$0.00';

  const undoDisabled = !undoActions.undo || undoActions.undo.length === 0;
  const noop = () => {};

  return (
    <>
      <div className = 'grid-div'>
          <Button title="Prev Month"  icon="left" label="prev month"
                  style={{gridColumn:1, gridRow:1}}
                  onClick = { () => prevSheet && prevSheet(1) }
                  disabled = { prevSheet === null } />
          <span style={{gridColumn:2, gridRow:1, width: '100%', textAlign: 'left'}}>
            <b>{month.toLocaleDateString(undefined, {year: 'numeric', month: 'long'})}</b>
          </span>
          <Button title="Next Month" icon="right" label="next month"
                  style={{gridColumn:3, gridRow:1}}
                  onClick = { () => nextSheet && nextSheet(1) }
                  disabled = { nextSheet === null } />
        <div style={{gridColumn:2, gridRow:2}} className='my-flex-box'>
          Balance: <em>{balance}</em>&nbsp;|&nbsp;Future: <em>{future}</em>|
          <Button style={{width: '16px', height: '16px', marginRight: '75px'}} title="Add Column" icon="plus" label="add column" onClick = {addColumn}
                  text="&nbsp;Add&nbsp;column"/>|
          <Button style={{width: '16px', height: '16px'}} title="Undo" icon="undo" label="undo" 
                  onClick = {undoDisabled ? noop : undoActions.restore}
                  text="&nbsp;Undo" disabled={undoDisabled}/>
        </div>
        <Button title="Derive Next Month"  icon="infer" label="derive next month"
                  style={{gridColumn:3, gridRow:2}}
                  disabled = { derive === null || nextSheet !== null }
                  onClick = { () => (derive !== null && nextSheet === null) && derive(1) } />
        <hr style={{gridColumnStart:1, gridColumnEnd:3, gridRow:3, width: '80%', height: '3px'}}/>
        <MobileView style={{gridColumn:1, gridRow:4}}>
              <Button title="Prev Column" icon="left" label="prev column"
                      onClick = { () => prevColumn && prevColumn() }
                      disabled = { !prevColumn } />
        </MobileView>
        <MobileView style={{gridColumn:2, gridRow:4}}>
              <ColumnHeader
                    col={len > idx ? Object.entries(sh.columns)[idx] : undefined}
                    modify={modify}
                    index={idx}/>
        </MobileView>
        <MobileView style={{gridColumn:3, gridRow:4}}>
              <Button title="Next Column" icon="right" label="next column"
                      onClick = { () => nextColumn && nextColumn() }
                      disabled = { !nextColumn } />
        </MobileView>
        <MobileView style={{gridColumnStart:1, gridColumnEnd:4, gridRow:5}}>
              <ColumnBody
                     transfers={len > idx ? Object.entries(sh.columns)[idx][1].transfers : undefined}
                     modify={modify}
                     index={idx}/>
        </MobileView>
        <BrowserView style={{gridColumnStart:1, gridColumnEnd:3, gridRow:4, width: '100%'}}>
          <div className = 'my-flex-box'>
            {sh && sh.columns
            ? Object.entries(sh.columns).map( (column, index) =>
                <span key={index}>
                   <ColumnHeader
                          col = {column}
                          modify={modify}
                          index={idx}/>
                   <ColumnBody
                          transfers = {column[1].transfers} colName = {column[0]}
                          modify={modify}
                          index={idx}/>
                </span>
              )
            : ''
            }
          </div>
       </BrowserView>
      </div>
    </>
  );
}

export default Sheet;
