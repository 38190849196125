import React from "react";
import { Ledger } from "./Ledger";
import './App.css';

import Menu from "./Menu";
import { DataProvider } from "./Context";

import {Amplify} from 'aws-amplify';
import awsExports from "./aws-exports";
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Auth } from "aws-amplify";

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import { version }  from './autobuild_version'
import Button from "./Button";

// Configure Amplify in index file or root file
Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID
  }
});

const formFields = {
  signIn: {
    username: {
      placeholder: 'Enter your email',
    },
  },
  signUp: {
    phone_number: {
      label: 'Phomne number:',
      placeholder: 'Enter your phone number:',
      isRequired: false,
      order: 2,
    },
    gender: {
      label: 'Gender:',
      placeholder: 'Enter your gender:',
      isRequired: false,
      order: 3,
    },
    name: {
      label: 'Name:',
      placeholder: 'Enter your name:',
      isRequired: false,
      order: 4,
    },
    password: {
      label: 'Password:',
      placeholder: 'Enter your Password:',
      isRequired: false,
      order: 5,
    },
    confirm_password: {
      label: 'Confirm Password:',
      order: 6,
    },
  },
  forceNewPassword: {
    password: {
      placeholder: 'Enter your Password:',
    },
  },
  resetPassword: {
    username: {
      placeholder: 'Enter your email:',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: 'Enter your Confirmation Code:',
      label: 'New Label',
      isRequired: false,
    },
    confirm_password: {
      placeholder: 'Enter your Password Please:',
    },
  },
  setupTOTP: {
    QR: {
      totpIssuer: 'test issuer',
      totpUsername: 'amplify_qr_test_user',
    },
    confirmation_code: {
      label: 'New Label',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: 'New Label',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false,
    },
  },
};

function App() {
  const [openAbout, setOpenAbout] = React.useState(false);
  const handleClickToOpenAbout = (evt) => { evt.preventDefault(); setOpenAbout(true); };
  const handleToCloseAbout = () => { setOpenAbout(false); };
  return (
        <Authenticator formFields={formFields}>
          <DataProvider>
            <header>
              <Menu />
            </header>
            <main>
              <Dialog open={openAbout}>
                <DialogTitle>{"About"}</DialogTitle>
                <DialogContent style={{display: "flex", flexFlow: "column", textAlign: "center"}}>
                    <span>Personal ledger management.</span>
                    <span>v {version}</span>
                    <a href="http://www.ravenbill.com">Ravenbill, LLC</a>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleToCloseAbout} label="Close" title="Close" icon="close"/>
                </DialogActions>
              </Dialog>
              <Ledger />
            </main>
            <footer>
              <a onClick={handleClickToOpenAbout} href="/">About</a>
              <a href="/contact">Contact</a>
            </footer>
          </DataProvider>
        </Authenticator>
  );
}

export default App;
