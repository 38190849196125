import {Money, parse} from './money';
import {Column, computeColumn} from './column';

export const computeSheet = (sheet) => {
    let acc = new Money();
    let future = new Money();
    for (let col in sheet.columns) {
        computeColumn(sheet.columns[col]);
        let amount = parse(sheet.columns[col].balance);
        if (sheet.columns[col].type === 'future') {
           future.add(amount);
        } else {
           acc.add(amount);
        }
    }
    future.add(acc);
    sheet.balance = acc.toString();
    sheet.future = future.toString();
};

export const addMonth = (date) => {
    const myStart = new Date(Date.parse(date));
    var y = myStart.getFullYear(), m = myStart.getMonth()+1, d = myStart.getDate();
    if (m === 12) { m = 0; y++; }
    if (m === 1 && d > 28) d = 28;
    if ((m === 3 || m === 5 || m === 8 || m === 10) && d === 31) d = 30;
    const myFinish = new Date(y, m, d);
    return myFinish.toString();
};

export class Sheet {
    constructor(obj) {
        obj && Object.assign(this, obj);
        let columns = {};
        for (let col in this.columns){
            columns[col] = new Column(this.columns[col]);
        }
        this.columns = columns;
    }

    compute() {
        computeSheet(this);
    }

    get(col, transfer) {
        if (col) {
            if (this.columns[col]) {
                if (transfer) return this.columns[col].get(transfer);
                return this.columns[col];
            }
            throw new Error(`sheet: column ${col} is not found`);
        }
        throw new Error("sheet: column name is empty");
    }

    del(colName, transfer) {
       const col = this.get(colName);
       if (transfer) {
           col.del(transfer);
       } else {
           delete this.columns[colName];
       }
    }

    checkDate(date) {
        const d = Date.parse(date);
        if (Number.isNaN(d)) throw new Error(`not parsable date: ${date}`);
        // check that date is in the month
        const myStart = Date.parse(this.start);
        const myFinish = Date.parse(addMonth(this.start));
        if (d <= myStart || d >= myFinish)
            throw new Error(`Date should be between ${this.start} and ${new Date(myFinish)}, but it is ${date}`);
    }

    add(what, columnName, amount, date, comment, exact) {
        this.checkDate(date);
        const col = this.get(columnName);
        col.add(what, amount, date, comment, exact);
        this.compute();
    }

    newColumn(name, description, type, place) {
        type = type || 'checking_account';
        const possibleTypes = ['checking_account', 'future'];
        const typeIdx =  possibleTypes.indexOf(type);
        if (typeIdx === -1) throw new Error(`Do not know how to add ${type}; Can add 'checking_account' or 'future'.`);
        if (! name) throw new Error(`Column name should not be empty`);
        if (! description) throw new Error(`Column description should not be empty`);
        if (this.columns[name]) throw new Error(`column ${name} already exists in this sheet`);
        const col = new Column({description: description, type: type, transfers: [], balance:'$0.0'});
        if (place) col.place = place;
        this.columns[name] = col;
    }

    move(fromColumn, transfer, toColumn) {
       const from = this.get(fromColumn);
       const tr = from.get(transfer);
       if (tr.length > 1) throw new Error(`We should move only one transfer at a time, '${transfer}' selects ${tr.length} transfers`);
       const to = this.get(toColumn);
       to.transfers.push(tr[0]);
       const idx = from.transfers.indexOf(tr[0]);
       from.transfers.splice(idx, 1);
       this.compute();
    }

    modify(what, columnName, transfer, new_value) {
        if (what === "date") this.checkDate(new_value);
        const col = this.get(columnName);
        col.modify(what, transfer, new_value);
        this.compute();
    }

    month() {
      const myStart = new Date(this.start);
      return (myStart.getFullYear() - 1970)*12 + myStart.getMonth();
    }
}

export const processPayment = (tr, future) => {
    tr.date = addMonth(tr.date);
    tr.exact = false;
    let found = false;
    future.transfers.forEach( (trf, indexf, arrayf) => {
        if (trf.payment && trf.comment === tr.comment) {
            found = true;
            const trd = Date.parse(tr.date), trfd = Date.parse(trf.date);
            const trm = parse(tr.payment), trfm = parse(trf.payment);
            if (trd > trfd) trf.date = `${new Date(trd)}`;
            trfm.add(trm);
            trf.payment = trfm.toString();
        }
    });
    if (!found) future.transfers.push(tr);
};

const patternPrevMonth = /prev.*month/i;
const patternSalary = /salary/i;
const patternAdvance = /advance/i;
const patternIrregular = /(savings|refund)/i;
const day = 24 * 60 * 60 * 1000;

export const inferSheet = (sheet) => {
    // step 0
    const next = new Sheet(JSON.parse(JSON.stringify(sheet)));
    // step 1
    next.start = addMonth(next.start);
    const start = Date.parse(next.start), finish = Date.parse(addMonth(next.start));
    const future = Object.entries(next.columns).filter(([name, col]) => col.type === "future")[0][1];
    const futureSalaryTransfers = future.transfers.filter(tr => patternSalary.test(tr.comment));
    future.transfers = future.transfers.filter(tr => !patternSalary.test(tr.comment));
    processSalary(futureSalaryTransfers, '', future, start, finish);
    Object.entries(next.columns).forEach( ([name, col], idx, columns) => {
        if (col.type === "future") return;
        const transfers = [];
        let prevMonthInserted = false;
        processSalary(col.transfers, name, future, start, finish);
        col.transfers.forEach( (tr, index, array) => {
            if (tr && tr.payment) processPayment(tr, future);
            else if (tr && patternPrevMonth.test(tr.comment) && !prevMonthInserted){
                prevMonthInserted = true;
                tr.date = addMonth(tr.date);
                tr.income = col.balance;
                transfers.push(tr);
            }
        })
        col.transfers = transfers;
    });
    computeSheet(next);
    return next;
};

const processSalary = (transfers, name, future, start, finish) => {
    let previousSalaryDate, period, avg = parse(''), n = 0;
    transfers.forEach( (tr, index, array) => {
        if (!tr || patternPrevMonth.test(tr.comment) || tr.payment) return;
        if (patternSalary.test(tr.comment)) {
             const d = Date.parse(tr.date);
             if (previousSalaryDate) {
                 let p = Math.abs(d - previousSalaryDate);
                 if (p > 13 * day && p < 15 * day) p = 14 * day;
                 if (p === 28 * day) p = p/2;
                 if (period) period = (period + p)/2;
                 else        period = p;
             }
             if (!previousSalaryDate || d > previousSalaryDate) previousSalaryDate = d;
             avg.add(parse(tr.income));
             n++;
        } else if(!patternIrregular.test(tr.comment)) {
            if (patternAdvance.test(tr.comment) && !tr.comment.toLowerCase().includes(name.toLowerCase()))
                tr.comment = `${tr.comment}, ${name}`;
            tr.date = addMonth(tr.date);
            tr.exact = false;
            future.transfers.push(tr);
        }
    });
    if (period) {
        let d = previousSalaryDate;
        avg.amount /= n;
        while (d < start) { d += period; }
        let cnt = 1;
        while (d < finish) {
            future.transfers.push( {
                income: avg.toString(),
                date: `${new Date(d)}`,
                exact: false,
                comment: `salary, ${cnt}, ${name}`,
            });
            d += period;
            cnt++;
        }
    } else if (previousSalaryDate) {
        future.transfers.push( {
            income: avg.toString(),
            date: addMonth(`${new Date(previousSalaryDate)}`),
            exact: false,
            comment: `salary, monthly, ${name}`,
        });
   }
};