import React, { useState, useEffect, createContext } from "react";
import Sheet from "./Sheet";

import * as datasheet from './lib/sheet';

/// responsible for:
/// 1. loading the ledger pages (sheets, one per month)
/// 2. keeping the ledger sheets in Context (like cache?)
/// 3. keeping the clipboard in Context
/// 4. keeping the undo list
/// 5. saving the state of the sheets in DB (uploading) on change.
/// 6. working with ledgers identified by Name unique per customer (?????):
///    a. allowing to select Ledger from drop down menu
///    b. loading Ledger name (???)
///    c. showing Ledger name
///    d. allow to change Ledger name
///    e. updating Ledger name (uploading)
///    f. creating new Ledger
///
///  I may start allowing one ledger per customer.
///  In the future we may define several (Personal/Family, Business 1, Business 2 etc).
///  Definition of ledger - money flow between ledgers should/may allow to pay taxes.
export const SheetsContext = createContext(null);
export const UndoContext = createContext([]);
export const ClipboardContext = createContext(null);
export const DragDropContext = createContext(null);

export const Ledger = () => {
  const [sheets, setSheets] = useState({sheets: {}, month: 0});
  const [undo, setUndo] = useState([]);
  const [clipboard, setClipboard] = useState(null);
  const [draggableTx, setDraggableTx] = useState(null);
  const [dropAction, setDropAction] = useState(null);

  useEffect(() => {
    fetch("fixture.json")
      .then((res) => res.json())
      .then((data) => new datasheet.Sheet(data))
      .then((dataSheet) => {
        var m = dataSheet.month();
        sheets.sheets[m] = dataSheet;
        setSheets({sheets: sheets.sheets, month: m});
      });
    }, [sheets.sheets, setSheets]);

  const frozenSheets = JSON.stringify(sheets);
  const storeFrozenState = () => {
    undo.push(frozenSheets);
    setUndo([...undo]);
  };
  const restoreFrozenState = () => {
    const oldSheets = JSON.parse(undo.pop());
    const newSheets = {};
    Object.entries(oldSheets.sheets).forEach( (arr, idx) => {
      newSheets[parseInt(arr[0])] = new datasheet.Sheet(arr[1]);
    });
    setSheets({sheets: newSheets, month: oldSheets.month});
    setUndo(undo && undo.length > 0 ? [...undo] : []);
  }

  const nextSheet = sheets.sheets[sheets.month+1] != null
      ? (id) => { setSheets({sheets: sheets.sheets, month:sheets.month+1}); } : null;
  const prevSheet = sheets.sheets[sheets.month-1] != null
      ? (id) => { setSheets({sheets: sheets.sheets, month:sheets.month-1}); } : null;
  const derive = sheets.sheets[sheets.month+1] == null
      ? (id) => {
        storeFrozenState();
        sheets.sheets[sheets.month+1] = datasheet.inferSheet(sheets.sheets[sheets.month]);
        setSheets({sheets: sheets.sheets, month: sheets.month+1});
      }
      : null;
  const editName = () => { alert(`Edit ledger name`); };
  const name = "Ledger Name";
  const modify = (sh) => {
    storeFrozenState();
    sh.compute();
    sheets.sheets[sheets.month] = sh;
    setSheets({sheets: sheets.sheets, month:sheets.month});
  };

  return (
    <>
      <div style = {{display: 'flex', flexFlow: 'row', gap: '10px'}} >
            <input style={{fontSize: 'x-large', fontWeight: 'bold'}} onChange={editName} type='text' value={name} />
      </div>
      <UndoContext.Provider value={{undo: undo, restore: restoreFrozenState}}>
        <DragDropContext.Provider value={{row : draggableTx, setRow: setDraggableTx, drop: dropAction, setDrop: setDropAction}}>
          <ClipboardContext.Provider value={{clipboard: clipboard, set: setClipboard}}>
            <SheetsContext.Provider value={sheets.sheets[sheets.month]}>
              <Sheet nextSheet={nextSheet} prevSheet={prevSheet} derive={derive} modify={modify}/>
            </SheetsContext.Provider>
          </ClipboardContext.Provider>
        </DragDropContext.Provider>
      </UndoContext.Provider>
  </>
  );
}
