import React, {useContext} from "react"
import Button from "./Button";
import {SheetsContext} from './Ledger';
import {renameKey} from './lib/utils';

// Responsible for Column header.
// One column per bank account / future income and payments
function ColumnHeader({col, modify, index}){
  const sh = useContext(SheetsContext);
  const [name, column] = col ? col : ["unknown", {}];
  const balance = column ? column.balance : '$0.00';
  const type = column ? column.type : 'undefined';
  const delColumn = (v) => {
    delete sh.columns[name];
    modify(sh);
  };
  const editName = (v) => {
    sh.columns = renameKey(sh.columns, name, v.target.value);
    modify(sh);
  };
  const editDescription = (v) => {
    column.description = v.target.value;
    modify(sh);
  };
  const changeType = (v) => {
    const newType = v.target.value;
    if (newType !== 'checking_account') {
      if (Object.entries(sh.columns).filter( (c, index) => c[1].type === newType).length) return;
    }
    column.type = newType;
    modify(sh);
  };

  return (
    <div style = {{minHeight: '80px'}}>
      <div style = {{display: 'flex', flexFlow: 'row', gap: '5px'}} >
         <span>Name: <input style={{fontSize: 'larger', fontWeight: 'bold'}} onChange={editName} type='text' value={name}  name={`name_${index}`} /></span>
         <span>
            Type:
            <em>
              <select onChange={changeType} value={type} style={{fontWeight: 'bold'}} name={`type_${index}`}>
                 {['future', 'checking_account', 'cash'].map(t =>
                   <option key={t}>{t}</option>)
                 }
              </select>
            </em>
         </span>
         <Button title="Delete Row" icon="trash" label="delete row" onClick = {delColumn} style={{width:'24px', height: '24px'}} />
      </div>
      <div style = {{display: 'flex', flexFlow: 'column', gap: '5px'}} >
        <span>
        Balance: {balance}
        </span>
        <textarea value={column ? column.description : ''} onChange={editDescription}  name={`description_${index}`}/>
      </div>
    </div>
  );
}

export default ColumnHeader;
