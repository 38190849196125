export const knownCurrency = { '': "empty", '$': "US Dollar", '₽': "Russian Ruble", '€': "Euro", '£': "UK Pound sterling", '¥': "Japanese Yen" };

// What to do with Chinese Reminbi: 'CN¥' or 'RMB' ???

export class Money {
    constructor(currency = '', amount = 0.0) {
        if (isNaN(amount)) throw new Error(`Cannot create money with amount == NaN`);
        if (knownCurrency[currency] === undefined) throw new Error(`Currency '${currency}' is undefined.`);
        this.currency = currency;
        this.amount = amount;
    }

    add(another) {
        if (another.amount !== 0) {
           if (this.currency === '') this.currency = another.currency;
           if (another.currency !== '' && another.currency !== this.currency)
               throw new Error(`Cannot add ${another.currency} to ${this.currency}`);
           if (isNaN(another.amount)) throw new Error(`Cannot add NaN`);
           this.amount += another.amount;
        }
    }

    multiply(coefficient){
        if (isNaN(coefficient)) throw new Error(`cannot multiply on '${coefficient}'`);
        this.amount *= coefficient;
    }

    toString() {return this.currency===''?'':`${this.currency}${this.amount.toFixed(2)}`}
};

export const add = (first, second) => {
    const m = new Money();
    m.add(first);
    m.add(second);
    return m;
};

export const parse = (amount) => {
	if (amount) {
          const arg = amount;
          amount = amount.trim();
    	    let currency = '$';
    	    let value = parseFloat(amount);
    	    if (isNaN(value)) {
            	let multiplier = 1.0;
            	if ('-' === amount.charAt(0)) {
                    multiplier = -1.0;
                    amount = amount.substring(1);
                }
                currency = amount.charAt(0);
                value = multiplier * parseFloat(amount.substring(1));
                if (isNaN(value)) throw new Error(`parseMoney: cannot parse '${arg}'`);
    	    } else {
                const last = amount.charAt(amount.length - 1);
                if (!/^[0-9.]$/.test(last)) currency = last;
            }
            if (knownCurrency[currency] === undefined) throw new Error(`parseMoney: Currency '${currency}' is undefined.`);
            return new Money(currency, value);
        }
        // throw "parseMoney: argument is empty";
        return new Money();
    };
