import {parse, Money} from "./money";

export const computeColumn = (column) => {
    let acc = new Money('', 0.0);
    for (let transfer of column.transfers) {
        let amount = new Money('', 0.0);
        if (transfer && transfer.income !== undefined) {
            amount = parse(transfer.income);
        } else if (transfer && transfer.payment !== undefined) {
            amount = parse(transfer.payment);
            amount.multiply(-1.0);
        } else if (transfer) {
            throw new Error(`Transfer should be either 'income' or 'payment' ${JSON.stringify(transfer, null, 2)}`);
        }
        acc.add(amount);
    }
    column.balance = acc.toString();
};

export class Column {
    constructor(obj) {
        obj && Object.assign(this, obj);
    }

    compute() {
        computeColumn(this);
    }

    get(transfer) {
        transfer = transfer.toUpperCase();
        return this.transfers.filter(tr => tr.comment.toUpperCase().indexOf(transfer) !== -1);
    }

    del(transfer) {
        const tr = this.get(transfer);
        if (tr.length === 0)  throw new Error(`Cannot find ${transfer} in the column`);
        if (tr.length >1)     throw new Error(`Can delete only one transfer, not ${tr.length}`);
        const index = this.transfers.indexOf(tr[0]);
        this.transfers.splice(index, 1);
    }

    add(what, amount, date, comment, exact) {
        const whatCanBeAdded = ['income', 'payment'];
        const whatIsAdded =  whatCanBeAdded.indexOf(what);
        if (whatIsAdded === -1) throw new Error(`Do not know how to add ${what}; Can add 'income' or 'payment'.`)
        if (!date) throw new Error("Date should not be empty");
        const d = Date.parse(date);
        if (d === 0) throw new Error(`Date should be properly formatted '${date}'`);
        if (!comment) throw new Error("Comment cannot be empty");
        if (exact === 'false' && this.type !== 'future') throw new Error(`Only 'future' column may include not-exact transfers.`);
        const m = parse(amount).toString();
        const transfer = {
            "comment": comment,
            "date": date, // parse
        };
        if (exact === 'false') transfer.exact = false;
        else                   transfer.exact = true;
        switch (whatIsAdded) {
            case 0: transfer.income = m; break;
            case 1: transfer.payment = m; break;
            default: throw new Error(`How did it come here? ${what} => ${whatIsAdded}`);
        }

        this.transfers.push(transfer);
        this.compute();
    }

    modify(what, transfer, new_value) {
        const whatCanBeChanged = ['amount', 'date', 'comment', 'exact'];
        const whatToChange = whatCanBeChanged.indexOf(what);
        if (whatToChange === -1) throw new Error(`do not know how to change '${what}', it should be on of the amount/date/comment/exact`);
        const transfers = this.get(transfer);
        if (transfers.length > 1)  throw new Error(`'${transfer}' corresponds to multiple transfers, should be one`);
        if (transfers.length ===0) throw new Error(`'${transfer}' not found`);
        switch (whatToChange) {
            case 0:
                const m = parse(new_value);
                if (transfers[0].income !== undefined)       transfers[0].income = m.toString();
                else if (transfers[0].payment !== undefined) transfers[0].payment = m.toString();
                else throw new Error(`transfer '${transfer}' does not have neither income nor payment\n${JSON.stringify(transfers[0], null, 2)}`);
                this.compute();
                break;
            case 1:
                Date.parse(new_value);
                transfers[0].date = new_value;
                break;
            case 2:
                if (new_value)  transfers[0].comment = new_value;
                else throw new Error('Comment cannot be empty');
                break;
            case 3:
                const exact = (new_value === 'true');
                if (this.type !== 'future' && !exact) throw new Error(`not exact values are allowed only in the 'future' column`);
                if (this.type === 'future') transfers[0].exact = exact;
                if (exact) transfers[0].exact = exact;
                break;
            default: throw new Error(`How did it come here? ${what} => ${whatToChange}`);
        }
    }
}
